﻿import { defineCustomElements, setNonce } from "@tambien/iqip-elements/loader";

const currentScriptElement = document.querySelector('[data-nonce]');
const nonce = currentScriptElement.dataset.nonce;
setNonce(nonce);
currentScriptElement.removeAttribute("data-nonce");
defineCustomElements();

window.addEventListener('appload', () => {
    document.body.classList.remove("d-none");
});

